import { Component, Vue, Prop, Ref, Model } from "vue-property-decorator";
import debounce from "lodash.debounce";
import { hashStringToColor, getFullName, getInitials } from "@helpers";
import { TeamActions, TeamGetters } from "@store/modules/team/types";
import { profileModule, teamModule } from "@store/namespaces";
import { ProfileGetters } from "@store/modules/profile/types";
import { Role } from "@/types/role";
import { hasRole } from "@/lib/role";
import { MultiSelectInstance } from "@/types/components/multiselect";
import { TeamMember } from "@/types/team";

@Component
export default class TeamMembersSelect extends Vue {
  @Ref("multiselect") private readonly multiselectRef?: MultiSelectInstance;

  @teamModule.Action("fetchTeamMembers")
  private readonly fetchTeamMembersAction!: TeamActions["fetchTeamMembers"];
  @teamModule.Getter("membersLoading")
  private readonly membersLoadingGetter!: TeamGetters["membersLoading"];
  @teamModule.Getter("members")
  private readonly membersGetter!: TeamGetters["members"];
  @teamModule.Getter("teamleadMembers")
  private readonly teamleadMembersGetter!: TeamGetters["teamleadMembers"];
  @teamModule.Getter("mediabuyerMembers")
  private readonly mediabuyerMembersGetter!: TeamGetters["mediabuyerMembers"];
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @profileModule.Getter("profileAvatar")
  private readonly profileAvatarGetter!: ProfileGetters["profileAvatar"];
  @profileModule.Getter("profileFullName")
  private readonly profileFullNameGetter!: ProfileGetters["profileFullName"];
  @profileModule.Getter("profileId")
  private readonly profileIdGetter!: ProfileGetters["profileId"];
  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];

  @Model("input", { type: Array, required: true })
  private readonly userEmails!: string[];

  @Prop({ type: Array, default: () => [] })
  private readonly selectedMediabuyers!: string[];
  @Prop({ type: Boolean, default: false })
  private readonly hiddenActivator!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly onlyTeamleadMembers!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly onlyMediabuyerMembers!: boolean;
  @Prop({ type: Array }) private readonly includeTeamleadEmails?: string[];
  @Prop({ type: String }) private readonly inputLabel?: string;
  @Prop({ type: [String, Number] }) private readonly minWidth!: string | number;
  @Prop({ type: Boolean, default: undefined })
  private readonly hiddenSearch!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly hiddenSelectAllControls!: boolean;

  private setActiveMember!: ReturnType<typeof debounce>;
  private activeMemberCid: string | null = null;
  private appendContentOffsetTop = 0;

  private get activeMember() {
    const activeMemberCid = this.activeMemberCid;

    return this.teamMembers.find(({ cid }) => cid === activeMemberCid);
  }

  private get hasAppendContentSlot() {
    return !!this.$scopedSlots["append-content"];
  }

  private get localInputLabel() {
    if (this.inputLabel) {
      return this.inputLabel;
    }

    if (this.hasAppendContentSlot) {
      return this.$vuetify.lang.t("$vuetify.dashboard.users.title");
    }

    if (this.onlyTeamleadMembers) {
      return this.$vuetify.lang.t("$vuetify.dashboard.user.role.teamlead");
    }

    if (this.onlyMediabuyerMembers) {
      return this.$vuetify.lang.t("$vuetify.dashboard.user.role.mediabuyer");
    }

    return this.$vuetify.lang.t("$vuetify.dashboard.users.title");
  }

  private get teamMembers() {
    let teamMembers = this.membersGetter;

    if (this.onlyMediabuyerMembers) {
      const includeTeamleadEmails = this.includeTeamleadEmails;

      teamMembers = includeTeamleadEmails?.length
        ? this.mediabuyerMembersGetter.filter(
            ({ parentMember }) =>
              parentMember?.email &&
              includeTeamleadEmails.includes(parentMember.email)
          )
        : this.mediabuyerMembersGetter;
    } else if (this.onlyTeamleadMembers) {
      teamMembers = this.teamleadMembersGetter;
    } else if (!this.userHasRoleGetter(Role.ROLE_TEAMLEAD)) {
      teamMembers = this.membersGetter.filter(
        (item) =>
          !hasRole(item.authority, [Role.ROLE_MEDIABUYER, Role.ROLE_TEAMLEAD])
      );
    }

    const items = teamMembers.map(
      ({
        email,
        firstName,
        lastName,
        enabled,
        avatar,
        cid,
        members,
        parentMember,
      }) => {
        const fullName = getFullName({
          firstName,
          lastName,
          fallback: email,
        });

        return {
          cid,
          avatar,
          email,
          enabled,
          fullName,
          members,
          parentMember,
          initials: getInitials(fullName),
        };
      }
    );

    if (
      !this.onlyMediabuyerMembers &&
      this.userHasRoleGetter([Role.ROLE_TEAMLEAD, Role.ROLE_OWNER])
    ) {
      items.unshift({
        cid: this.profileIdGetter.toString(),
        avatar: this.profileAvatarGetter,
        email: this.profileEmailGetter,
        fullName: this.profileFullNameGetter,
        initials: getInitials(this.profileFullNameGetter),
        enabled: true,
        parentMember: undefined,
        members: [],
      });
    }

    return items;
  }

  private get items() {
    const userEmails = this.userEmails;

    return Object.freeze(
      this.teamMembers.concat([]).sort((a, b) => {
        if (userEmails.includes(a.email) && !userEmails.includes(b.email)) {
          return -1;
        }

        return 0;
      })
    );
  }

  private get totalItemsLength() {
    if (!this.hasAppendContentSlot) {
      return;
    }

    return this.items.reduce((count, { members }) => {
      const membersCount = members?.length ?? 0;

      return count + membersCount + 1;
    }, 0);
  }

  private get selectedItemsLength() {
    if (!this.hasAppendContentSlot) {
      return;
    }

    return this.selectedMediabuyers.length + this.userEmails.length;
  }

  private getSelectedMediabuyers(teamlead: TeamMember) {
    return (
      teamlead.members?.filter(({ email }) =>
        this.selectedMediabuyers.includes(email)
      ).length || 0
    );
  }

  private selectAll() {
    this.multiselectRef?.selectAll();
  }

  private getTeamMemberColor(email: string) {
    return hashStringToColor(email);
  }
  private onMouseEnterMemberItem(item: TeamMember, event: MouseEvent) {
    if (!this.onlyTeamleadMembers) {
      return;
    }

    this.setActiveMember(item.cid);

    const memberItemEl = (event.currentTarget as HTMLElement).parentElement
      ?.parentElement;

    const memberItemsEl = memberItemEl?.parentElement?.parentElement;

    this.appendContentOffsetTop =
      memberItemEl && memberItemsEl
        ? memberItemEl.offsetTop - memberItemsEl.scrollTop
        : 0;
  }

  private onMouseLeaveMemberItem() {
    this.setActiveMember.cancel();
  }

  private onUpdateShowedList(showed: boolean) {
    this.setActiveMember(showed ? this.activeMemberCid : null);
  }

  private async fetchTeamMembers() {
    await this.fetchTeamMembersAction();

    this.$emit("ready", this.teamMembers);
  }

  private created() {
    this.setActiveMember = debounce(
      (cid: string | null) => {
        this.activeMemberCid = cid || null;
      },
      200,
      {
        maxWait: 300,
      }
    );
  }

  private mounted() {
    this.fetchTeamMembers();
  }

  private beforeDestroy() {
    this.setActiveMember.cancel();
  }
}
