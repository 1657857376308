import { Component, Vue, Ref, Model } from "vue-property-decorator";
import { bankModule, profileModule } from "@store/namespaces";
import { BankActions, BankGetters } from "@store/modules/bank/types";
import { ProfileGetters } from "@store/modules/profile/types";
import { MultiSelectInstance } from "@/types/components/multiselect";

@Component
export default class BanksMultiselect extends Vue {
  @Ref("multiselect") private readonly multiselectRef?: MultiSelectInstance;

  @Model("input", { type: Array, required: true })
  private readonly bankIds!: number[];

  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];
  @bankModule.Action("fetchTeamBanks")
  private readonly fetchTeamBanksAction!: BankActions["fetchTeamBanks"];
  @bankModule.Getter("loadingTeamBanks")
  private readonly loading!: BankGetters["loadingTeamBanks"];
  @bankModule.Getter("teamBanks")
  private readonly items!: BankGetters["teamBanks"];

  private async fetchBanks() {
    await this.fetchTeamBanksAction();

    this.$emit("ready", this.items);
  }

  private selectAll() {
    this.multiselectRef?.selectAll();
  }

  private mounted() {
    this.fetchBanks();
  }
}
