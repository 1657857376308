import { Action } from "vuex";
import API from "@api";
import { RootState } from "@store/types";
import { ProfileState, ProfileGetters } from "../types";
import { AxiosError } from "axios";
import { ApiErrorResponse } from "@/types/api";
import { ErrorStatus } from "@/types/errorStatus";
import { Profile } from "@/types/profile";
import { setUserProfileInfo } from "@/lib/auth";
import { checkAvatar } from "@helpers";

const fetchProfile: Action<ProfileState, RootState> = async (
  context,
  payload = {}
) => {
  const { isSaveErrorStatus = false } = payload;

  try {
    const getters = context.getters as ProfileGetters;

    const { data } = await API.profile.fetchProfile();

    setUserProfileInfo({
      email: data.email,
      id: data.id,
    });

    const diffProfile: Partial<Profile> = {};

    if (!data.language) {
      diffProfile.language = getters["profileLanguage"]();
    }

    if (!checkAvatar(data.avatar)) {
      diffProfile.avatar = getters["profileAvatar"];
    }

    if (Object.keys(diffProfile).length > 0) {
      API.profile.updateProfile(diffProfile);
    }

    data.language = context.state.profile?.language || data.language;

    context.commit("setProfile", { ...data, ...diffProfile });
  } catch (err) {
    const error = err as AxiosError<ApiErrorResponse>;
    const status = error.response?.status;

    if (status === 401 || !isSaveErrorStatus) {
      return Promise.reject(err);
    }

    switch (status) {
      case 500:
        context.commit("setErrorStatus", ErrorStatus.INTERNAL_SERVER_ERROR, {
          root: true,
        });
        break;

      default:
        context.commit("setErrorStatus", ErrorStatus.SERVICE_UNAVAILABLE, {
          root: true,
        });
        break;
    }
  }
};

export default fetchProfile;
