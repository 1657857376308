var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('MultiSelect',{ref:"multiselectTeamlead",attrs:{"selected-items":_vm.teamleadEmails,"loading":_vm.loading,"items":_vm.items,"selected-items-length":_vm.selectedItemsLength,"total-items-length":_vm.totalItemsLength,"item-height":43,"max-height":200,"hidden-search":false,"hidden-select-all-controls":false,"input-label":_vm.$vuetify.lang.t('$vuetify.dashboard.users.title'),"input-icon":"$userGroup","item-value":"email","item-text":"fullName"},on:{"update:showed-menu":_vm.onUpdateShowedList,"mouseenter:item":_vm.onMouseEnterMemberItem,"mouseleave:item":_vm.onMouseLeaveMemberItem,"input":_vm.onInputTeamleadEmails},scopedSlots:_vm._u([{key:"icon",fn:function({ item: { avatar, email, initials } }){return [(avatar)?_c('Avatar',{staticClass:"flex-shrink-0 mr-2 team-members-select__avatar",attrs:{"avatar-name":avatar,"size":"30"}}):_c('VuetifyLazyComponent',{staticClass:"flex-shrink-0 mr-2 team-members-select__avatar",attrs:{"color":_vm.getTeamMemberColor(email),"size":"33","component-name":"VAvatar"}},[_c('span',{staticClass:"white--text text-subtitle-2"},[_vm._v(" "+_vm._s(initials)+" ")])])]}},{key:"after-text",fn:function({ item }){return [(_vm.isShowNestedMembers && item.members?.length)?_c('p',{staticClass:"text-caption grey--text font-weight-medium mb-0"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.dashboard.selected_mediabuyer_from_to", _vm.getSelectedMediabuyers(item), item.members.length ))+" ")]):(!item.enabled)?_c('p',{staticClass:"red--text text-caption d-block mb-0",style:({ lineHeight: 1 })},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.info.deleted"))+" ")]):_vm._e()]}},(_vm.isShowNestedMembers)?{key:"after-item",fn:function({ item }){return [_c('v-btn',{style:({
        marginLeft: '-13px',
        visibility: !item.members?.length ? 'hidden' : '',
      }),attrs:{"tile":"","icon":"","x-small":"","height":"100%"},on:{"mouseenter":function($event){return _vm.onMouseEnterMemberItem(item, $event)},"mouseleave":function($event){return _vm.onMouseLeaveMemberItem(item, $event)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-menu-right ")])],1)]}}:null,(_vm.isShowNestedMembers)?{key:"append-content",fn:function(){return [_c('v-slide-x-transition',{attrs:{"mode":"out-in"}},[(_vm.activeMember && _vm.activeMember.members.length)?_c('div',{style:({
          position: 'absolute',
          left: 'calc(100% + 5px)',
          top: '0px',
          pointerEvents: 'none',
        })},[_c('div',{style:({
            transform: `translateY(${_vm.appendContentOffsetTop + 146}px)`,
            pointerEvents: 'auto',
            transition: 'transform 0.2s ease-out',
          })},[_c('MultiSelect',{attrs:{"selected-items":_vm.mediabuyerEmails,"loading":_vm.loading,"items":_vm.mapItems(_vm.activeMember.members),"item-height":43,"max-height":200,"min-width":270,"hidden-search":false,"hidden-select-all-controls":false,"hidden-activator":"","item-value":"email","item-text":"fullName"},on:{"input":_vm.onInputMediabuyerEmails},scopedSlots:_vm._u([{key:"icon",fn:function({ item: { avatar, email, initials } }){return [(avatar)?_c('Avatar',{staticClass:"flex-shrink-0 mr-2 team-members-select__avatar",attrs:{"avatar-name":avatar,"size":"30"}}):_c('VuetifyLazyComponent',{staticClass:"flex-shrink-0 mr-2 team-members-select__avatar",attrs:{"color":_vm.getTeamMemberColor(email),"size":"33","component-name":"VAvatar"}},[_c('span',{staticClass:"white--text text-subtitle-2"},[_vm._v(" "+_vm._s(initials)+" ")])])]}}],null,false,2078925833)})],1)]):_vm._e()])]},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }