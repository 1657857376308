var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('MultiSelect',_vm._g({ref:"multiselect",staticClass:"banks-multiselect",attrs:{"selected-items":_vm.cardGroupIds,"items":_vm.localItems,"loading":_vm.loading,"item-value":_vm.itemValue,"item-text":_vm.itemText,"item-height":38,"max-height":192,"input-label":_vm.$vuetify.lang.t('$vuetify.dashboard.table.header.group_name'),"input-icon":"$layerGroup"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('VuetifyLazyComponent',{style:({
        ...(item.style || {}),
        height: '24px',
        fontSize: '13px',
      }),attrs:{"color":!item.style
          ? _vm.hashStringToColor(`${item[_vm.itemText]}_${item.owner}`)
          : undefined,"text-color":!item.style ? 'white' : undefined,"label":"","component-name":"VChip"}},[_c('span',{staticClass:"text-truncate flex-shrink-1 flex-grow-1"},[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])])]}}])},_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }