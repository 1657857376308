import { Component, Vue, Prop, Ref, Model } from "vue-property-decorator";
import { cardModule } from "@store/namespaces";
import { CardActions, CardGetters } from "@store/modules/card/types";
import {
  formatCardNumber,
  getCardIconSrc,
  getCardPaymentSystem,
} from "@/lib/creaditCard";
import { MultiSelectInstance } from "@/types/components/multiselect";

@Component
export default class CardsMultiselect extends Vue {
  @Ref("multiselect") private readonly multiselectRef?: MultiSelectInstance;

  @Prop({ type: Array }) private readonly includeIds?: number[];
  @Model("input", { type: Array, required: true })
  private readonly cardIds!: number[];

  @cardModule.Getter("cardsList")
  private readonly cardsListGetter!: CardGetters["cardsList"];
  @cardModule.Getter("loadingCards")
  private readonly loadingCardsGetter!: CardGetters["loadingCards"];
  @cardModule.Getter("loadedCards")
  private readonly loadedCardsGetter!: CardGetters["loadedCards"];
  @cardModule.Action("fetchCards")
  private readonly fetchCardsAction!: CardActions["fetchCards"];

  private get sortedCards() {
    return this.cardsListGetter
      .concat([])
      .sort(
        (a, b) => +this.cardIds.includes(b.id) - +this.cardIds.includes(a.id)
      );
  }

  private get items() {
    const includeIds = this.includeIds;
    const items = Array.isArray(includeIds)
      ? this.sortedCards.filter((card) => includeIds.includes(card.id))
      : this.sortedCards;

    return items;
  }

  private get searchByFields() {
    return ["note"];
  }

  private getCardIconSrc(cardNumber: string) {
    return getCardIconSrc(getCardPaymentSystem(cardNumber));
  }

  private formatCardNumber(val: string) {
    return formatCardNumber(val);
  }

  private selectAll() {
    this.multiselectRef?.selectAll();
  }

  private initCards() {
    this.fetchCardsAction();

    this.$watch(
      () => {
        return this.loadedCardsGetter;
      },
      (loadedCards) => {
        if (!loadedCards) return;

        this.$emit("ready", this.cardsListGetter);
      },
      {
        immediate: true,
      }
    );
  }

  private mounted() {
    this.initCards();
  }
}
