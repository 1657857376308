import Vue, { PluginObject } from "vue";

import BaseImg from "@/components/BaseImg/BaseImg.vue";
import BaseDropDown from "@/components/BaseDropDown/BaseDropDown.vue";
import Menu from "@/components/Menu/Menu.vue";
import Dialog from "@/components/Dialog/Dialog.vue";
import FormField from "@/components/FormField/FormField.vue";
import MobileScreen from "@/components/MobileScreen/MobileScreen.vue";
import MultiSelect from "@/components/MultiSelect/MultiSelect.vue";
import BanksMultiselect from "@/components/BanksMultiselect/BanksMultiselect.vue";
import CardsMultiselect from "@/components/CardsMultiselect/CardsMultiselect.vue";
import CurrencyMultiselect from "@/components/CurrencyMultiselect/CurrencyMultiselect.vue";
import CardGroupMultiselect from "@/components/CardGroupMultiselect/CardGroupMultiselect.vue";
import TeamMembersSelect from "@/components/TeamMembersSelect/TeamMembersSelect.vue";
import FilterTableTopBar from "@/components/FilterTableTopBar/FilterTableTopBar.vue";
import TeamMembersMultiselect from "@/components/TeamMembersMultiselect/TeamMembersMultiselect.vue";
import VuetifyLazyComponent from "@/components/VuetifyLazyComponent/VuetifyLazyComponent.vue";

const GlobalComponentsPlugin: PluginObject<undefined> = {
  install() {
    const components = {
      BaseImg,
      BaseDropDown,
      Menu,
      Dialog,
      FormField,
      MobileScreen,
      MultiSelect,
      BanksMultiselect,
      CurrencyMultiselect,
      CardsMultiselect,
      CardGroupMultiselect,
      TeamMembersSelect,
      TeamMembersMultiselect,
      FilterTableTopBar,
      VuetifyLazyComponent,
      Avatar: () => import("@/components/Avatar/Avatar.vue"),
      DateRangePicker: () =>
        import("@/components/DateRangePicker/DateRangePicker.vue"),
      DesktopScreen: () =>
        import("@/components/DesktopScreen/DesktopScreen.vue"),
      CardGroupList: () =>
        import("@/components/CardGroupList/CardGroupList.vue"),
      Trans: () => import("@/components/Trans/Trans.vue"),
      LangSwitcher: () => import("@/components/LangSwitcher/LangSwitcher.vue"),
      LazyComponent: () =>
        import("@/components/LazyComponent/LazyComponent.vue"),
      LottieBanner: () => import("@/components/LottieBanner/LottieBanner.vue"),
      BottomSheet: () => import("@/components/BottomSheet/BottomSheet.vue"),
      PasswordTextField: () =>
        import("@/components/PasswordTextField/PasswordTextField.vue"),
      CardIssueLimit: () =>
        import("@/components/CardIssueLimit/CardIssueLimit.vue"),
      MaskedText: () => import("@/components/MaskedText/MaskedText.vue"),
      ExpandCardTile: () =>
        import("@/components/ExpandCardTile/ExpandCardTile.vue"),
      InfiniteList: () => import("@/components/InfiniteList/InfiniteList.vue"),
      TechnicalScreen: () =>
        import("@/components/TechnicalScreen/TechnicalScreen.vue"),
      DataTable: () => import("@/components/DataTable/DataTable.vue"),
      ConfirmDialog: () =>
        import("@/components/ConfirmDialog/ConfirmDialog.vue"),
      ConfirmCard: () => import("@/components/ConfirmCard/ConfirmCard.vue"),
      WalletTransferFunds: () =>
        import("@/components/WalletTransferFunds/WalletTransferFunds.vue"),
      LockedPageBanner: () =>
        import("@/components/LockedPageBanner/LockedPageBanner.vue"),
    };

    components.CardIssueLimit();
    components.WalletTransferFunds();

    Object.entries(components).forEach(([id, constructor]) => {
      Vue.component(id, constructor);
    });
  },
};

export default GlobalComponentsPlugin;
