import { Action } from "vuex";
import API from "@api";
import { RootState } from "@store/types";
import { Role } from "@/types/role";
import { TeamMember } from "@/types/team";
import { TeamState } from "../types";
import { ProfileGetters } from "../../profile/types";

let fetchTeamHierarchyPromise: Promise<TeamMember> | null = null;

const fetchTeamHierarchy: Action<TeamState, RootState> = async (
  { state, commit, rootGetters },
  payload = {}
) => {
  const { fromCache = true } = payload;

  const userHasRole: ProfileGetters["userHasRole"] =
    rootGetters["profile/userHasRole"];

  const canViewTeamPage: ProfileGetters["canViewTeamPage"] =
    rootGetters["profile/canViewTeamPage"];

  if (
    !userHasRole([Role.ROLE_OWNER, Role.ROLE_ACCOUNTANT, Role.ROLE_TEAMLEAD]) ||
    !canViewTeamPage
  ) {
    return {
      members: [],
    };
  }

  if (fromCache && state.teamHierarchy.value) {
    return;
  }

  if (fetchTeamHierarchyPromise) {
    return fetchTeamHierarchyPromise;
  }

  commit("setTeamHierarchyLoading", true);

  fetchTeamHierarchyPromise = API.team.fetchTeamHierarchy();

  try {
    const teamHierarchy = await fetchTeamHierarchyPromise;

    const sortTeamMembers = (teamMember: TeamMember) => {
      if (!teamMember.members?.length) {
        return;
      }

      const members: TeamMember[] = [];

      teamMember.members.forEach((member) => {
        sortTeamMembers(member);

        if (member.enabled) {
          members.unshift(Object.freeze(member));
        } else {
          members.push(Object.freeze(member));
        }
      });

      teamMember.members = members;
    };

    sortTeamMembers(teamHierarchy);

    commit("setTeamHierarchy", teamHierarchy);
  } finally {
    commit("setTeamHierarchyLoading", false);
    fetchTeamHierarchyPromise = null;
  }
};

export default fetchTeamHierarchy;
