import { Vue } from "vue-property-decorator";
import { LangCode } from "@/types/lang";
import { metadata } from "./config";

export function initMetadata({ app }: { app: Vue }) {
  const titleEl = document.head.querySelector("title");

  if (!titleEl) {
    return;
  }

  const metaDescriptionEl = document.createElement("meta");
  const metaOgDescriptionEl = document.createElement("meta");
  const metaOgImageEl = document.createElement("meta");
  const metaTwitterImageEl = document.createElement("meta");

  metaDescriptionEl.setAttribute("property", "description");
  metaOgDescriptionEl.setAttribute("property", "og:description");
  metaOgImageEl.setAttribute("property", "og:image");
  metaOgImageEl.setAttribute("property", "twitter:image");

  titleEl.insertAdjacentElement("afterend", metaOgImageEl);
  titleEl.insertAdjacentElement("afterend", metaTwitterImageEl);
  titleEl.insertAdjacentElement("afterend", metaOgDescriptionEl);
  titleEl.insertAdjacentElement("afterend", metaDescriptionEl);

  console.log("titleEl", titleEl);

  app.$watch(
    () => {
      return app.$vuetify.lang.current as LangCode;
    },
    (langCode) => {
      const metadataCurrent = metadata[langCode] || metadata.en;

      if (!metadataCurrent) {
        return;
      }

      const { description, imageUrl } = metadataCurrent;

      metaDescriptionEl.setAttribute("content", description);
      metaOgDescriptionEl.setAttribute("content", description);
      metaOgImageEl.setAttribute("content", imageUrl);
      metaTwitterImageEl.setAttribute("content", imageUrl);
    },
    {
      immediate: true,
    }
  );
}
