import { LangCode } from "@/types/lang";

export const metadata: Partial<
  Record<
    LangCode,
    {
      description: string;
      imageUrl: string;
    }
  >
> = {
  [LangCode.ENGLISH]: {
    description:
      "Multicards.io — your best partner in the world of virtual cards and agency accounts. Transparent statistics, convenient team features, and over 30 BINs across 5 GEOs. Free trials and tailored conditions for each client.",
    imageUrl: require("./img/banner-en.png"),
  },
  [LangCode.RUSSIAN]: {
    description:
      "Multicards.io — твой надежный партнер в мире виртуальных карт и агентских аккаунтов. Прозрачная статистика, удобные командные функции и более 30 БИН-ов на 5 ГЕО. Бесплатный тест и индивидуальные условия для каждого клиента.",
    imageUrl: require("./img/banner-ru.png"),
  },
  [LangCode.UKRAINIAN]: {
    description:
      "Multicards.io — твой надежный партнер в мире виртуальных карт и агентских аккаунтов. Прозрачная статистика, удобные командные функции и более 30 БИН-ов на 5 ГЕО. Бесплатный тест и индивидуальные условия для каждого клиента.",
    imageUrl: require("./img/banner-ru.png"),
  },
};
